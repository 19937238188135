<template>
  <div>
    <div class="panel panel-default mb-3">
      <div class="panel-heading" :style="'background:' + task.type.custom_color">
        <h5 class="mb-0">
          <strong class="font-medium">#{{ task.id }}</strong>
          <strong class="margin-l-5 font-medium" style="position: absolute;">
            {{ task.type.name }}
          </strong>

          <span v-if="task && (task.creator || task.client)" class="label font-small mt-1 pull-right"
                :class="task.creator ? 'label-primary' : ((!task.creator && task.client) ? 'label-success' : 'label-default')">
            {{ task.creator ? 'Сотрудник' : ((!task.creator && task.client) ? 'Клиент' : '') }}
          </span>
        </h5>
        <small class="mt-0">
          {{ task.created_date }} от
          <span class="font-medium" v-if="task.creator">
            {{ task.creator.name }}
          </span>
          <span class="font-medium" v-if="!task.creator && task.client">
            {{ task.client.name }}
            <span class="text-muted">(клиент)</span>
          </span>

          <template v-if="task.tags.length">
            <span v-for="tag in task.tags" class="label font-small-xxs mt-1 pull-right" :class="tag.label_color_class">
              {{ tag.name }}
            </span>
          </template>

          <span class="mt-0 mb-0 font-small-xs">
            <br/>
            Удалено <strong class="font-medium">{{ task.deleted_date }}</strong>
          </span>
        </small>
      </div>

      <div class="panel-body">
        <div class="row">
          <div class="col-12 mb-1 mt-1">
            <p v-if="task.car" class="mb-0">
              <strong>Автомобиль: </strong>
              <span>{{ task.car.title }} (ID: {{ task.car.id }} {{ task.car.branch.title }})</span>
            </p>
            <p v-else class="mb-0">
              <strong class="text-muted">Автомобиль не выбран</strong>
            </p>
            <p v-if="task.client" class="mb-1">
              <strong>Клиент: </strong>
              <span>
                {{ task.client.name }} (ID: {{ task.client.id }})
                <i class="fa fa-phone-square font-small"></i>
                <a :href="'tel:+' + task.client.phone" class="font-medium">
                  {{ task.client.phone }}
                </a>
              </span>
            </p>
            <p v-else class="mb-1">
              <strong class="text-muted">Клиент не выбран</strong>
            </p>
            <p v-if="(task.responsible && $store.getters['auth/authUser'].id !== task.responsible_id) ||
                 !task.responsible"
               class="mb-1">
              <strong>Исполнитель: </strong>
              <span v-if="task.responsible">
                {{ task.responsible.name }} (ID: {{ task.responsible_id }} {{ task.responsible.branch ? task.responsible.branch.title : '' }})
              </span>
              <span class="font-small text-muted" v-else>
                Не назначен
              </span>
            </p>
            <p v-if="task.branch" class="mb-1">
              <strong>Филиал: </strong>
              <span>
                {{ task.branch.title }}, <span class="text-muted">{{ task.branch.address }}</span>
              </span>
            </p>
            <p v-if="task.term_date" class="mb-1">
              <strong>Срок выполнения: </strong>
              <span class="font-medium">{{ task.term_date }}</span>
            </p>

            <task-item-details-block v-if="Object.keys(task.details).length"
                                     :task_details="task.details"
                                     :task_car_price="task.car ? task.car.price : 0"
                                     :with_delimiter="false"/>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <button v-if="([1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id))"
                class="btn btn-warning pull-left full-height"
                title="Восстановить" type="button"
                v-on:click="restoreTask(task.id)">
          <i class="fas fa-trash-restore-alt"></i>
        </button>

        <router-link :to="{ name: 'task.details', params: { taskId: task.id }}"
                     class="btn btn-success pull-right margin-l-5"
                     title="Детали"
                     type="button">
          <i class="fa fa-info-circle"></i>
        </router-link>
        <router-link :to="{ name: 'task.details', params: { taskId: task.id, currentTab: 'Файлы' }}"
                     class="btn btn-info pull-right margin-l-5"
                     title="Файлы"
                     type="button">
          <i class="fas fa-file" style="margin-right: 2px;"/> <small class="text-white">{{ task.files_count }}</small>
        </router-link>
        <router-link :to="{ name: 'task.details', params: { taskId: task.id, currentTab: 'История' }}"
                     class="btn btn-default pull-right"
                     title="История"
                     type="button">
          <i class="fas fa-book"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component('task-item-details-block', (resolve) => {
  return require(['./../item_blocks/details-block.vue'], resolve);
});

export default {
  name: 'task-archive-item',

  components: {},

  props: {
    task: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  data: () => ({}),

  computed: {},

  methods: {
    restoreTask(taskId) {
      this.$dialog.confirm('Вы уверены что хотите восстановить запись?', this.$dialogOptions).then(() => {
        API.apiClient.put('/tasks/restore/' + taskId).then(() => {
          this.EventBus.$emit('card-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
